import { useCallback, useEffect, useState } from 'react'
import Video, { LocalVideoTrack, LocalAudioTrack } from 'twilio-video'
import * as VideoProcessors from '@twilio/video-processors'
import { useAudioVideo } from '../hooks'
import professionalRoomBackgroundImage from '../assets/img/professional-room-background.png'

export function useLocalAudioTrack() {
  const [track, setTrack] = useState<LocalAudioTrack>()

  useEffect(() => {
    Video.createLocalAudioTrack().then((newTrack) => {
      setTrack(newTrack)
    })
  }, [])

  useEffect(() => {
    const handleStopped = () => setTrack(undefined)
    if (track) {
      track.on('stopped', handleStopped)
      return () => {
        track.off('stopped', handleStopped)
      }
    }
  }, [track])

  return track
}

export function useLocalVideoTrack() {
  const [track, setTrack] = useState<LocalVideoTrack>()
  const { selectedVideoDeviceId }: any = useAudioVideo()

  const getLocalVideoTrack = useCallback(
    () =>
      Video.createLocalVideoTrack({
        frameRate: 24,
        height: { min: 480, ideal: 720 }, //720,
        width: { min: 640, ideal: 1280 }, //1280,*/
        name: 'camera',
        deviceId: selectedVideoDeviceId,
      }).then((newTrack) => {
        console.debug(
          '[useLocalTracks] Loading and setting background processor'
        )

        /*console.log(
          'professionalRoomBackgroundImage',
          professionalRoomBackgroundImage,
          typeof professionalRoomBackgroundImage
        )
        
        const img = new Image()
        img.src = professionalRoomBackgroundImage
        img.onerror = () => {
          console.error('failed to load bg processor image')
        }
        img.onload = async () => {
          const bg = new VideoProcessors.VirtualBackgroundProcessor({
            assetsPath: '/twilio',
            backgroundImage: img,
            maskBlurRadius: 5,
          })

          console.log('*** bgProcessor bg =', bg)
          await bg.loadModel()
          console.log('*** bg model loaded')

          newTrack.addProcessor(bg)
          console.log('*** addProcessor called')
        }*/

        // newTrack.addProcessor(
        //   new VideoProcessors.GaussianBlurBackgroundProcessor({
        //     assetsPath: '/twilio',
        //     pipeline: VideoProcessors.Pipeline.WebGL2,
        //     debounce: true,
        //   }),
        //   {
        //     inputFrameBufferType: 'video',
        //     outputFrameBufferContextType: 'webgl2',
        //   }
        // )
        // console.log('*** addProcessor called')

        setTrack(newTrack)
        return newTrack
      }),
    []
  )

  useEffect(() => {
    // We get a new local video track when the app loads.
    getLocalVideoTrack()
  }, [getLocalVideoTrack])

  useEffect(() => {
    const handleStopped = () => setTrack(undefined)
    if (track) {
      track.on('stopped', handleStopped)
      return () => {
        track.off('stopped', handleStopped)
      }
    }
  }, [track])

  return [track, getLocalVideoTrack] as const
}

export function useLocalTracks() {
  const audioTrack = useLocalAudioTrack()
  const [videoTrack, getLocalVideoTrack] = useLocalVideoTrack()

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track !== undefined
  ) as (LocalAudioTrack | LocalVideoTrack)[]

  return { localTracks, getLocalVideoTrack }
}
