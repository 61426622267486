import React, { useEffect, useState } from 'react'

import { navigate } from '@reach/router'

import { useStoreState } from '../../../store/store.hooks'
import { useRoom } from '../../../twilio-doki/useRoom'
import { useLocalTracks } from '../../../twilio-doki/useLocalTracks'
import useParticipants from '../../../twilio-doki/useParticipants'
import { DokiRoom } from '../../../components/VideoCall/DokiRoom'
import { useRoomState } from '../../../twilio-doki/useRoomState'
import { post } from '../../../services/request'
import { CircularProgress } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { playCallingSoundEffect } from '../../../lib/soundEffects'
import { DoctorCallRing } from './DoctorCallRing'
import { mixpanel } from '../../../util/mixpanel'

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 500,
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    borderRadius: 15,
  },
  loading: {
    color: '#fff',
  },
}))

export const ProfRoom = () => {
  const classes = useStyles()
  const { twilioRoomUniqueName, twilioToken } = useStoreState(
    (state) => state.incomingRequest
  )

  const { localTracks, getLocalVideoTrack } = useLocalTracks()
  const { room, connect } = useRoom(localTracks, console.error, {
    name: twilioRoomUniqueName,
  })
  const roomState = useRoomState(room)
  const [callCancelable, setCallCancelable] = useState(false)

  async function handleConnect() {
    await connect(twilioToken)
    try {
      mixpanel.track('callerAck')
      await post('/call/callerAck', {
        successfull: true,
        roomNumber: twilioRoomUniqueName,
      })
    } catch (e: any) {
      console.error(e.stack)
    }
  }

  useEffect(() => {
    handleConnect()
  }, [])

  useEffect(() => {
    if (roomState === 'connected') {
      setCallCancelable(true)
    } else {
      setCallCancelable(false)
    }
  }, [roomState])

  console.log({ room, state: room.state })

  const participants = useParticipants(room)

  // Play calling soundeffect
  const [soundIntervalId, setSoundIntervalId] = useState<NodeJS.Timeout | null>(
    null
  )

  const {
    profile: { profileContents },
  } = useStoreState((state) => state)

  useEffect(() => {
    if (profileContents.userTypeId === 1) {
      playCallingSoundEffect()
      const intervalId = setInterval(() => playCallingSoundEffect(), 8000)
      setSoundIntervalId(intervalId)
      return () => clearInterval(intervalId)
    }
  }, [])

  const [hasOtherParticipantArrived, setHasOtherParticipantArrived] =
    useState(false)

  useEffect(() => {
    if (participants.length > 0) {
      setHasOtherParticipantArrived(true)
      soundIntervalId && clearInterval(soundIntervalId)
    }
  }, [participants])

  const handleCancelCall = async (twilioRoomUniqueName: string | undefined) => {
    await post('/call/end', {
      roomNumber: twilioRoomUniqueName,
      reason: 'professionalCanceled',
    })

    room.disconnect()
    console.log('Twilio room has been disconnected')
    if (profileContents.userTypeId === 1) {
      navigate('/professional/dashboard')
    }
  }

  if (!hasOtherParticipantArrived) {
    return (
      <DoctorCallRing
        cancelable={callCancelable}
        onCancelCall={handleCancelCall}
      />
    )
  }

  if (roomState === 'connected')
    return (
      <DokiRoom
        room={room}
        localTracks={localTracks}
        getLocalVideoTrack={getLocalVideoTrack}
      />
    )
  return (
    <div className={classes.loadingContainer}>
      <CircularProgress className={classes.loading} />
    </div>
  )
}
