import React, { createContext, useContext, useEffect } from 'react'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { navigate } from '@reach/router'

const AccountContext = createContext(null)

const AccountContextProvider = ({ children }) => {
  const { account, activeVoucher, subscriptionRetrieved } = useStoreState(
    (state) => state.subscription
  )
  const { fetchSubscription } = useStoreActions((state) => state.subscription)
  const {
    profile: { profileContents },
  } = useStoreState((state) => state)
  const isProfessional = profileContents.userTypeId === 1

  useEffect(() => {
    if (!isProfessional) {
      // If the account has been fetched, let's determine if we need to
      // ask the user to provider a new voucher code
      if (subscriptionRetrieved === true) {
        // We need to set an account if there is no record of it
        if (!account) {
          navigate('/account')
        }
        // Or if the account is not general public, but the voucher is expired
        if (!!account && account.key !== 'general-public' && !activeVoucher) {
          navigate('/account')
        }
      }
    }
  }, [account, activeVoucher, subscriptionRetrieved])

  useEffect(() => {
    fetchSubscription()
  }, [fetchSubscription])

  return (
    <AccountContext.Provider value={null}>{children}</AccountContext.Provider>
  )
}

const useAccount = () => {
  const context = useContext(AccountContext)
  return context
}

export { AccountContextProvider, useAccount }
