const CONSULTANCY_TYPES = {
  DOCTOR: 1,
  PSYCHOLOGIST: 2,
  CORONA: 3,
}

export { CONSULTANCY_TYPES }
export { EXTERNAL_FILES } from './externalFiles'
export { FEATURES } from './features'
export { MYMEDIO_URLS } from './settings'
export { ROUTES } from './routes'
export { SPECIALIZATIONS } from './specializations'
