import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    minHeight: '560px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
    },

    '@media (max-width: 720px) and (orientation: landscape)': {
      width: '100%',
      height: '100%',
      position: 'fixed',
      borderRadius: 0,
      zIndex: 100,
      top: 0,
      left: 0,
    },
  },
  videoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    overflow: 'hidden',
    background: 'white',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,

    '@media (max-width: 720px) and (orientation: landscape)': {
      width: '100%',
      height: '100%',
      position: 'fixed',
      borderRadius: 0,
      zIndex: 100,
      top: 0,
      left: 0,
    },
  },
  bodyContainer: {
    zIndex: 3,
    width: '100%',
    height: '100%',
  },
  headerContainer: {
    paddingTop: 12,
    textAlign: 'center',
    width: '100%',
    height: 80,
    top: 0,
    left: 0,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    position: 'absolute',
    background:
      'linear-gradient(180deg, rgba(30, 40, 100, 0.6) 0%, rgba(30, 40, 100, 0.0001) 100%)',

    overflowY: 'hidden',
  },
  header: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '33px',
    fontFamily: 'Nunito, Arial, sans-serif',
  },
  bottomContainer: {
    height: 120,
    width: '100%',
    content: "''",
    display: 'block',
    position: 'absolute',
    justifyContent: 'center',
    background:
      'linear-gradient(180deg, rgba(30, 40, 100, 0.0001) 0%, rgba(30, 40, 100, 0.9) 100%)',
    bottom: 0,
    right: 0,
    left: 0,
    overflowY: 'hidden',
    zIndex: -1,
  },
  timer: {
    color: '#FFFFFF',
    fontSize: '20px',
    lineHeight: '27px',
    position: 'absolute',
    left: '2%',
  },
  smallVideoContainer: {
    display: 'flex',
    boxShadow: '0px 10px 20px rgba(100, 115, 175, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    background: 'black',
    position: 'absolute',
    borderRadius: '15px',
    width: '150px',
    height: '80px',
    top: '9%',
    right: '1%',
    zIndex: 10,

    '@media (max-width: 720px) and (orientation: landscape)': {
      zIndex: 101,
      position: 'fixed',
    },
  },
  buttonBlock: {
    zIndex: 100,
    display: 'flex',
    marginTop: 15,
    justifyContent: 'center',
  },
  roundButton: {
    width: 50,
    height: 50,
    marginTop: 20,
    position: 'relative',
    margin: '0 8px',
    cursor: 'pointer',

    '&:before': {
      content: "''",
      opacity: 0.7,
      width: 50,
      height: 50,
      display: 'block',
      borderRadius: 25,
      background: '#1E2864',
      position: 'absolute',
      top: 0,
      zIndex: '-1',
    },
  },
  roundButtonRed: {
    '&:before': {
      background: '#F01E5A',
    },
  },
  roundButtonIcon: {
    color: 'white',
    marginLeft: 14,
    marginTop: 13,
  },
  dialogueContainer: {
    zIndex: 11,
    display: 'flex',
    justifyContent: 'center',
    width: 1000,
    background:
      'linear-gradient(180deg, rgba(30, 40, 100, 0.0001) 0%, rgba(30, 40, 100, 0.9) 100%)',
    position: 'relative',
  },
  dualButtonContainer: {
    display: 'flex',
    position: 'absolute',
    bottom: '1%',
    flexDirection: 'row',
    paddingLeft: '20%',
    paddingRight: '20%',
    marginTop: 5,
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      padding: 12,
      flexDirection: 'column',
    },
  },
  button: {
    height: 50,
    width: 300,
    ' &:hover': {
      backgroundColor: '#FFFFFF',
    },
    '&:nth-child(1)': {
      marginRight: 10,
    },
    '&:nth-child(2)': {
      marginLeft: 10,
    },

    [theme.breakpoints.down('xs')]: {
      margin: 10,
    },
  },
  dialogueText: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#FFFFFF',
    zIndex: 10,
    position: 'absolute',
    bottom: '45%',

    [theme.breakpoints.down('xs')]: {
      top: '15%',
    },
  },
  closeIcon: {
    top: 40,
    right: 20,
    color: 'white',
    position: 'absolute',
    cursor: 'pointer',
  },
  cameraSwitchIcon: {
    color: 'white',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  collapsButton: {
    width: '100%',
    borderTop: '1px solid #C1C7DF',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '15px',
    boxShadow: '0px 10px 20px rgba(100, 115, 175, 0.2)',

    ' &:hover': {
      backgroundColor: '#FFFFFF',
    },
  },

  patientInfo: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    borderTop: '1px solid #C1C7DF',
  },
  complaintDetailInput: {
    width: '100%',
    borderRadius: 15,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#6473AF',
  },
  complaints: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  complaintDetailText: {
    fontSize: 20,
    marginTop: 5,
  },
}))

export { useStyles }
