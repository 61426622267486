import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'

const localStorageKey = 'preferredLanguage'
const supportedLanguages = ['hu', 'en', 'ua']
let defaultLanguage = 'hu'

if (window && window.location && window.location.hostname) {
  const hostname = window.location.hostname.toLowerCase()
  if (hostname) {
    if (hostname.indexOf('ua') > -1) {
      defaultLanguage = 'ua'
    } else if (['clinic', 'tester'].some((x) => hostname.indexOf(x) > -1)) {
      defaultLanguage = 'en'
    }
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    fallbackLng: defaultLanguage,
    lng: defaultLanguage,
    supportedLngs: supportedLanguages,
    //debug: true,
    interpolation: {
      escapeValue: false,
    },
  })

const preferredLanguage = localStorage.getItem(localStorageKey)
if (preferredLanguage) {
  if (supportedLanguages.some((x) => x === preferredLanguage)) {
    i18n.changeLanguage(preferredLanguage)
    console.info(
      'Preferred language set from local storage:',
      preferredLanguage
    )
  } else {
    i18n.changeLanguage(defaultLanguage)
    console.info(
      'Invalid preferred language read from local storage, setting default',
      preferredLanguage
    )
  }
} else {
  i18n.changeLanguage(defaultLanguage)
  console.info('No preferred language detected, setting default')
}

export default i18n
