import React, { Fragment, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { navigate } from '@reach/router'

import { Avatar, Typography, Grid } from '@material-ui/core'
import CallIcon from '@material-ui/icons/Call'

import { GradientButton } from '../../../../components/Button'

import { useStyles } from './styles'
import { get, post } from '../../../../services/request'
import { useStoreState, useStoreActions } from '../../../../store/store.hooks'

import { playMatchSoundEffect } from '../../../../lib/soundEffects'
import { LinearCallIndicator } from '../../../../components/LinearCallIndicator'
import { LanguageDisplay } from '../../../../components/LanguageDisplay'
import { useTranslation } from 'react-i18next'
import { DownloadAttachments } from '../../../../components'

const PatientMatch = () => {
  const classes = useStyles()
  const { incomingRequestContents } = useStoreState(
    (state) => state.incomingRequest
  )
  const { setTwilioCredentials } = useStoreActions(
    (state) => state.incomingRequest
  )
  const complaints = incomingRequestContents?.complaints
    .map((complaint) => complaint.name)
    .join(', ')
  const { t } = useTranslation('patientMatch')
  // TODO: eliminate duplication, see DoctorCallFeedback and DoctorCallRing
  const description = incomingRequestContents?.description
  const patientProfilePictureUrl = incomingRequestContents?.requester?.avatarUrl
  const patientName = incomingRequestContents?.requester?.name || t('patient')
  const patientAge = dayjs().diff(
    incomingRequestContents?.requester?.birthday,
    'year'
  )
  const patientAgeString = patientAge ? `(${patientAge})` : ''
  const language = incomingRequestContents?.language
  const { childName, childAge, specialization } = incomingRequestContents || {}
  const [accountName, setAccountName] = useState(null)

  useEffect(() => {
    playMatchSoundEffect()

    // Retrieve professional instructions associated with this voucher
    const fetchVoucher = async () => {
      const response: any = await get(
        `vouchers/${incomingRequestContents?.couponCode}`
      )

      if (response.account) {
        setAccountName(response.account)
      }
    }

    if (incomingRequestContents && incomingRequestContents.couponCode) {
      fetchVoucher()
    }
  }, [])

  const handleCallStart = async () => {
    const response = await post<{
      twilioRoomUniqueName: string
      twilioToken: string
    }>('call/init', { recipientId: incomingRequestContents?.requester.id })

    setTwilioCredentials(response)
    navigate('/professional/room')
  }

  const hasSpecialization = specialization && specialization.key
  let specializationComponent = null

  if (hasSpecialization) {
    switch (specialization.key) {
      case 'pediatrics':
        specializationComponent = (
          <>
            <span className={classes.complaintText}>
              <b>{t('childName')}:</b> {childName}
            </span>
            <span className={classes.complaintText}>
              <b>{t('childAge')}:</b> {childAge}
            </span>
          </>
        )
        break
    }
  }

  return (
    <Fragment>
      <Grid container className={classes.patientMatchTop}>
        <div className={classes.bgLayover}></div>
        <Grid container className={classes.patientMatchContainer}>
          <Grid item xs={12} className={classes.ComplaintDetailContainer}>
            <Avatar
              alt="patient-profile-picture"
              src={patientProfilePictureUrl}
              className={classes.avatar}
            />
            <h2 className={classes.name}>
              <strong>{patientName}</strong> {patientAgeString}
            </h2>
            <h3 className={classes.patientFound}>
              {t<string>('patientFound')}
            </h3>
            <Typography className={classes.birth} component="p">
              {t<string>('patientFoundInfo')}
            </Typography>
            <Typography className={classes.timeLimit} component="p">
              {t<string>('timeLimit')}
            </Typography>
            <LinearCallIndicator />
            <GradientButton
              onClick={() => handleCallStart()}
              className={classes.callButton}
            >
              <CallIcon className={classes.callIcon} />
              {t<string>('call')}
            </GradientButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.consultancyInfo}>
        <span className={classes.complaintLabel}>
          {t<string>('complaintLabel')}
        </span>
        <span className={classes.complaintList}>{complaints}</span>
        <span className={classes.complaintText}>{description}</span>
        {hasSpecialization && specializationComponent}
        <span className={classes.complaintText}>
          {t<string>('complaintLanguage')}{' '}
          <LanguageDisplay language={language} />
        </span>
        <span className={classes.complaintText}>
          {t<string>('couponCode')} {incomingRequestContents?.couponCode}
        </span>
        {accountName && (
          <span className={classes.complaintText}>
            {t<string>('professionalInstructions')} {accountName}
          </span>
        )}
        <DownloadAttachments shortenFileNames={true} />
      </Grid>
    </Fragment>
  )
}

export { PatientMatch }
